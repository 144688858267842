module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VJD6RYH73F"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agricola Hydraulika Siłowa","short_name":"agricola","start_url":"","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"static/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c3e2af709fdeaf813bbc2e8335683995"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
